<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class=" elevation-1">
            Edit Product
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="productForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="productname"
                      label="Product Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="priceRules"
                      v-model="price"
                      label="Product Price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="instock"
                      label="Stock Availability"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="stockquantity"
                      label="Stock Quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="priceRules"
                      v-model="productcost"
                      label="Product Cost"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="category"
                      label="Category"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="subcategory"
                      label="Subcategory"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="black"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["productid"],
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      productname: null,
      price: null,
      instock: null,
      stockquantity: null,
      productcost: null,
      category: null,
      subcategory: null,
      product:[],
      msg: null,
      valid: true,
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      priceRules:[(v) => !!v || "Required",
      (v) => /^\d+(?:[.,]\d+)*$/.test(v) || "Must be price",],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    validate() {
      if (this.$refs.productForm.validate()) {
        this.editUser();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/info",
        method: "GET",
        params: {
          id: this.productid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          var product = response.data.data;
          this.productname = product.productname;
          this.price = product.price;
          this.instock = product.instock;
          this.stockquantity = product.stockquantity;
          this.productcost = product.productcost;
          this.category = product.category.name;
          this.subcategory = product.subcategory.name;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var product = {};
      product["productname"] = this.productname;
      product["price"] = this.price;
      product["instock"] = this.instock;
      product["stockquantity"] = this.stockquantity;
      product["productcost"] = this.productcost;
      product["category"] = this.category;
      product["subcategory"] = this.subcategory;
      product["id"] = this.productid;
      axios({
        method: "POST",
        url: "/Product/Edit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: product,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>